import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { BACKEND_ENDPOINTS } from '../../constants/endpoints';
import { ActionsEnum } from '../../enums/actions.enum';
import {
  CustomQuestionnaire,
  HardSkill,
  SoftSkill,
} from '../../models/ThunksInterfaces/retrieveAvailableSkills';
import { http } from '../../utils/http';
import { setErrorMessage, setSuccessMessage } from '../popup/actions';

export const RETRIEVE_AVAILABLE_SKILLS = createAsyncThunk(
  ActionsEnum.RETRIEVE_AVAILABLE_SKILLS,
  async ({
    companyId,
    languageId,
  }: {
    companyId?: number;
    languageId?: number;
  }): Promise<SoftSkill[]> => {
    let soft_skills: SoftSkill[];
    try {
      const config: AxiosRequestConfig = { method: 'GET' };
      if (companyId) {
        config.params = { ...config.params, companyId };
      }
      if (languageId) {
        config.params = { ...config.params, languageId };
      }
      const response = await http(BACKEND_ENDPOINTS.RETRIEVE_AVAILABLE_SKILLS, config);
      soft_skills = response.data.data.softSkills;
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return soft_skills;
  }
);

export const RETRIEVE_ALL_HARD_SKILLS = createAsyncThunk(
  ActionsEnum.RETRIEVE_ALL_HARD_SKILLS,
  async (): Promise<HardSkill[]> => {
    let hard_skills: HardSkill[];
    try {
      const config: AxiosRequestConfig = { method: 'GET' };
      const response = await http(BACKEND_ENDPOINTS.RETRIEVE_HARD_SKILLS, config);
      hard_skills = response.data.data.hard_skills;
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return hard_skills;
  }
);

export const RETRIEVE_CUSTOM_QUESTIONNAIRES = createAsyncThunk<CustomQuestionnaire[], number>(
  ActionsEnum.RETRIEVE_CUSTOM_QUESTIONNAIRES,
  async (companyId): Promise<CustomQuestionnaire[]> => {
    let custom_questionnaires: CustomQuestionnaire[] = [];
    try {
      const config: AxiosRequestConfig = { method: 'GET', params: { companyId } };
      const response = await http(BACKEND_ENDPOINTS.RETRIEVE_CUSTOM_QUESTIONNAIRES, config);
      custom_questionnaires = response.data.data.questionnaires;
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        throw Error(e.response?.data.error.message);
      }
    }
    return custom_questionnaires;
  }
);

export const DELETE_CUSTOM_QUESTIONNAIRE = createAsyncThunk<number, number>(
  ActionsEnum.DELETE_CUSTOM_QUESTIONNAIRE,
  async (questionnaireId, { dispatch }) => {
    try {
      await http(`${BACKEND_ENDPOINTS.RETRIEVE_CUSTOM_QUESTIONNAIRES}/${questionnaireId}`, {
        method: 'DELETE',
      });
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        dispatch(setErrorMessage({ message: 'skills.customQuestionnaires.delete.reject' }));
        throw Error(e.response?.data.error.message);
      }
    }
    dispatch(setSuccessMessage({ message: 'skills.customQuestionnaires.delete.confirm' }));
    return questionnaireId;
  }
);
